import { useRef, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import * as THREE from 'three';
import { useThree, useLoader, useFrame } from '@react-three/fiber';
import { Text3D } from "@react-three/drei";
import { useSpring, animated } from '@react-spring/three'

import panchang from '../../fonts/panchang.json';
import mapUrl from '../../img/matcap-rainbow8.jpg';
import mapUrl2 from '../../img/matcap-rainbow-top3.jpg';

export default function Hero(props) {

    const [map] = useLoader(THREE.TextureLoader, [mapUrl])
    const [map2] = useLoader(THREE.TextureLoader, [mapUrl2])
    const { width: w, height: h } = useThree((state) => state.viewport);
    const text = useRef(null);
  
    const location = useLocation();
    const [active, setActive] = useState(true)
    const [loaded, setLoaded] = useState(0)

    const [reverseAnim, setReverseAnim] = useState(true)

    const { y } = useSpring({
      y: active ? 1 : -100,
      config: { mass: 5, tension: 10, friction: 10, precision: 0.0001, duration: 2000 } 
    })

    const { spring } = useSpring({
      spring: loaded,
      config: { mass: 5, tension: 10, friction: 10, precision: 0.0001, duration: 2000 }
    });

    const [springs, api] = useSpring(
      () => ({
        rotationZ: 0,
        config: { mass: 5, tension: 10, friction: 20, duration: 4700}
      }),
      []
    )

    const rotation = spring.to([0, 1], [Math.PI*2, 0]);
    const scale = spring.to([0, 1], [3, 1]);

    useEffect(()=>{
      if (location.pathname !== '/') {
        setActive(false)
      } else {
        setActive(true)
      }
    },[])

    useEffect(()=>{
      if (location.pathname !== '/') {
        setActive(false)
      } else {
        setActive(true)
      }
    },[location])

    useEffect(()=>{
      if (props.fullyLoaded) {
        setLoaded(1)
      }
    },[props.fullyLoaded])

    useEffect(()=>{
      if (active) {
        const animInterval = setInterval(()=>{
          setReverseAnim(!reverseAnim)
        },5000)
        return ()=>{clearInterval(animInterval)}
      }
    })

    useEffect(()=>{
      if (reverseAnim) {
        api.start({
          rotationZ: -(Math.PI / 50),
        })
      } else {
        api.start({
          rotationZ: (Math.PI / 50),
        })
      }
    },[reverseAnim])
   
    return (
      <animated.group 
        ref={text} 
        position={y}
        rotation-y={active && rotation}
        rotation-z={active && springs.rotationZ}
        scale-x={scale}
        scale-z={scale}
      >
        <Text3D
          font={panchang}
          position={[-w/2.5, -20, 20]}
          rotation={[-Math.PI / 2, 0,  0]}
          scale={[1, 1, 1]}
          size={w/5}
          curveSegments={200}
          bevelEnabled
          bevelSize={4}
          bevelThickness={4}
          bevelOffset={-1}
          bevelSegments={30}
          height={20}
        >
          SÖ
          <meshMatcapMaterial attach="material" color="white" matcap={map} toneMapped={false} blending={THREE.NoBlending}/>
        </Text3D>
        <Text3D
          font={panchang}
          position={[-w/2.5, -20, 20]}
          rotation={[-Math.PI / 2, 0,  0]}
          scale={[1, 1, 1]}
          size={w/5}
          curveSegments={200}
          bevelEnabled
          bevelSize={4}
          bevelThickness={4}
          bevelOffset={-1}
          bevelSegments={30}
          height={20}
        >
          SÖ
          <meshMatcapMaterial attach="material" color="white" matcap={map2} toneMapped={false} blending={THREE.AdditiveBlending}/>
        </Text3D>
      </animated.group>
    )
  }