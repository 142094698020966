import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from './scrollIndicator.module.scss'


export default function ScrollIndicator({wheelDetails}) {

  const navigate = useNavigate();

  useEffect(()=>{
    // console.log('WHEEL ::: ',wheelDetails)
    if (wheelDetails.scale > 80) {
      navigate('/playground')
    }
    else if (wheelDetails.scale < -80) {
      navigate('/portfolio')
    }
  },[wheelDetails])

  return (
    <div className={styles.scrollIndicatorWrapper}>
      <div className={styles.scrollIndicatorNavItem}>
        <Link to="portfolio">
          Portfolio
        </Link>
      </div>
      <div className={styles.scrollIndicatorBackground}>
        <div 
          className={styles.scrollIndicator} 
          style={{
            transform: wheelDetails.up ? `scaleY(${(100 - wheelDetails.scale)*0.01})` : `scaleY(${(100 + wheelDetails.scale) * 0.01})`,
            transformOrigin: wheelDetails.up ? 'center top': 'center bottom'
          }}> <span> &uarr;</span> <span>&darr;</span></div>
      </div>
      <div className={styles.scrollIndicatorNavItem}>
        <Link to="playground">
          Playground
        </Link>
      </div>
    </div>
  )
}