import { useEffect, useRef, useState } from "react"
// import styles from './textTransition.module.scss'

export default function TextTransition({children}) {

    const wrapper = useRef(null)
    const chars = ['#','!','?','♥','★']
    const nbLetters = 4

    const [fontSize, setFontSize] = useState('16')
    const [randomLetters, setRandomLetters] = useState([])
    const [transitionFinished, setTransitionFinished] = useState(false)
    const [currentId, setCurrentId] = useState(null)


    useEffect(()=>{

        if (wrapper.current) {
            let element = document.getElementById(wrapper.current.parentNode.id)
            let style = window.getComputedStyle(element, null).getPropertyValue('font-size');
            setCurrentId(element)
            setFontSize(parseFloat(style))
            randomChars()
            element.addEventListener('mouseenter',()=>{
                setTransitionFinished(true)
            })
            element.addEventListener('mouseleave',()=>{
                setTransitionFinished(false)
            })
            element.style.width = (parseFloat(style)*0.55)*Array.from(children).length+'px'
            // element.style.display = 'inline-block'
        }

    },[])

    const randomChars = () => {

        let randomCharsArray = []

        Array.from(children).forEach(()=>{
            let tempArray = []
            for (let i = 0; i < nbLetters; i++) {
                const random = Math.floor(Math.random() * chars.length)
                tempArray.push(chars[random])
            }
            randomCharsArray.push(tempArray)
        })

        setRandomLetters(randomCharsArray)

    }

    return(
        <span ref={wrapper} 
            style={{height: fontSize+'px', width: (fontSize*0.55)*Array.from(children).length+'px', overflow: 'hidden', display: 'flex'}} 
        >
            {Array.from(children).map((letter, idx)=>{
                return <span key={letter+'animatedLetter'+idx+currentId} className='letterColumn' 
                    style={{
                        transform: idx % 2 == 0 ? 
                            !transitionFinished ? `translateY(-${fontSize*(nbLetters+1)}px)` : 'translateY(0)'
                            :
                            !transitionFinished ? `translateY(0)` : `translateY(-${fontSize*(nbLetters+1)}px)`
                        ,
                        width: (fontSize*0.55)+'px',
                        height: (fontSize*(nbLetters + 2))+'px'
                    }}
                >
                    <span style={{height: fontSize+'px'}}>{letter}</span>
                    {randomLetters.length && randomLetters[idx].map((char, index)=>{
                        return <span key={currentId+'char'+char+idx+index} style={{height: fontSize+'px'}}>{char}</span>
                    })}
                    <span style={{height: fontSize+'px'}}>{letter}</span>
                </span>
            })}
        </span>
    )
}