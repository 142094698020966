import { useEffect, useRef, useState } from 'react'
import styles from './portfolioCard.module.scss'
import colors from '../../styles/colors.module.scss'
import torusImg from '../../img/shapes/torus2.png'
import knotImg from '../../img/shapes/knot.png'
import appleImg from '../../img/shapes/apple3.png'
import heartImg from '../../img/shapes/heart.png'
import cylinderImg from '../../img/shapes/cylinder2.png'

export default function ProjectCard3(props) {
  const [rect, setRect] = useState({width:0, height:0, top: 0, left: 0})
  const [mousePosition, setMousePosition] = useState({x:0, y:0})
  const [mousePositionKeeped, setMousePositionKeeped] = useState({x:0, y:0})
  // const [seedValue, setSeedValue] = useState(1)
  const cardRef = useRef()


  useEffect(()=>{
    console.log(props.name, props.transitionComplete)
  },[props.transitionComplete])


  const setCardRect = () => {
    if (cardRef.current) {
      var rect = cardRef.current.getBoundingClientRect();
      setRect({
        width: rect.width, 
        height: rect.height, 
        top: rect.top, 
        left: rect.left
      })
    }
  }

  const makePerspective = (e) => {
    
    if (rect.width !== 0 && rect.height !== 0 && rect.top !== 0 && rect.left !== 0) {

      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top; 

      var centerX = rect.width / 2
      var centerY = rect.height / 2

      var finalX = x > centerX ? x - centerX : -(centerX - x)
      var finalY = y > centerY ? y - centerY : -(centerY - y)

      setMousePosition({x:finalX, y:finalY});
      setMousePositionKeeped({x:finalX, y:finalY});
    }
  }

  const resetPerspective = () => {
    setMousePosition({x:0, y:0})
  }

  const goToDetail = () => {
    props.cardOpenEvent(rect, props.slug)
  }

  // const getRandomArbitrary = (min, max) => {
  //   return Math.floor(Math.random() * (max - min) + min);
  // }

  return (
    <>
    <div 
      className={styles.portfolioCardWrapper}
      onMouseMove={(e)=>makePerspective(e)} 
      onMouseLeave={resetPerspective} 
      onMouseEnter={setCardRect}
      ref={cardRef}
    >
      <div
        className={styles.portfolioCard} 
        style={{
          transform: `perspective(600px) rotateY(${mousePosition.x / 5}deg) rotateX(${mousePosition.y / 5}deg)`,
          transition: mousePosition.x !== 0 && mousePosition.y !== 0 ? 'none' : 'transform 0.6s ease-in',
        }}
        onClick={()=>goToDetail()}
      >
        {/* <svg className={styles.cardHologram}
          viewBox="0 0 100 100"
          preserveAspectRatio='none'
        >
          <defs>

            <radialGradient id="hologradient" x1="0" x2="1" y1="0" y2="1">

                  <stop offset="20%" stopColor="rgba(0,0,0,0)" />
                  {
                      [colors.blue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                          return <stop key={'card3offset1'+idx} offset={`${idx*11+40}%`} stopColor={color} />
                      })
                  }
                  <stop offset="100%" stopColor="rgba(0,0,0,0)" />

            </radialGradient>

          </defs>
          <rect id="holograms" x="-50" y="-50" width="200" height="200" fill={'url(#hologradient)'}
            transform={`translate(${mousePositionKeeped.x * 0.12} ${mousePositionKeeped.y*0.14})`}
          >
          </rect>
          

        </svg> */}
        <svg className={styles.cardReflection}
          viewBox="0 0 100 100"
          preserveAspectRatio='none'
        >
          <defs>

            <linearGradient id="lightgradient" x1="0" x2="1" y1="0" y2="1">
              <stop offset="0.3" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset="0.35" stopColor="rgba(160,209,251, 0.2)"></stop>
              <stop offset="0.40" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset={0.5} stopColor="rgba(160,209,251, 0.4)">
              </stop>
              <stop offset="0.6" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset="0.8" stopColor="rgba(160,209,251, 0.2)"></stop>
            </linearGradient>

          </defs>
          
          <rect x="-50" y="-50" width="200" height="200" fill={'url(#lightgradient)'}
            transform={`translate(${mousePositionKeeped.x * 0.1} ${mousePositionKeeped.y*0.1})`}
          ></rect>
        </svg>




        <div className="tagWrapper">
          {props.tags.map((tag)=>{
            return <div className="tag" key={tag+props.slug}>{tag}</div>
          })}
        </div>
        {/* <a href="" className={styles.more}>More</a> */}
        <div className={styles.titleContainer}>
          <h3>{props.name}</h3>
          <div className={styles.imgContainer}>
            <svg
            className={styles.cardImg}
              viewBox="0 0 100 100"
              // width="100" height="100"
              preserveAspectRatio='none'
              style={{opacity: props.transitionComplete ? 0.8 : 0.2}}
              >
              <defs>
                <filter id={`displacementFilter${props.slug}`}>
                  
                  <feTurbulence 
                    type="turbulence" 
                    baseFrequency="0.03" 
                    numOctaves="1" 
                    result="noise" 
                    seed={props.idx+1}
                    />
                  <feDisplacementMap in="SourceGraphic" in2="noise" scale="60" xChannelSelector="R" yChannelSelector="G" />

                </filter>
                <filter id="nnnoise-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
                  <feTurbulence type="fractalNoise" baseFrequency="0.102" numOctaves="4" seed="15" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence">
                  </feTurbulence>
                  <feSpecularLighting surfaceScale="15" specularConstant="0.75" specularExponent="20" lightingColor="white" x="0%" y="0%" width="100%" height="100%" in="turbulence" result="specularLighting">
                          <feDistantLight azimuth="3" elevation="100"></feDistantLight>
                  </feSpecularLighting>
    
              </filter>

                <radialGradient id="radialGradient3"  cx = "50%" cy = "50%" r = "50%" fx = "25%" fy = "25%">
                  <stop offset="10%" stopColor={colors.darkGrey} />
                  <stop offset="20%" stopColor={colors.darkGrey} />
                  {
                      [colors.blue, colors.blue, colors.middleBlue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                          return <stop key={'card3offset1'+idx} offset={`2${idx}%`} stopColor={color} />
                      })
                  }
                  <stop offset="28%" stopColor={colors.darkGrey} />
                  <stop offset="50%" stopColor={colors.darkGrey} />
                  {
                      [colors.blue, colors.blue, colors.middleBlue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                          return <stop key={'card3offset2'+idx} offset={`5${idx}%`} stopColor={color} />
                      })
                  }
                  <stop offset="58%" stopColor={colors.darkGrey} />
                  <stop offset="70%" stopColor={colors.darkGrey} />
                  {
                      [colors.blue, colors.blue, colors.middleBlue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                          return <stop key={'card3offset3'+idx} offset={`7${idx}%`} stopColor={color} />
                      })
                  }
                  <stop offset="78%" stopColor={colors.darkGrey} />
                  <stop offset="100%" stopColor={colors.darkGrey} />
                </radialGradient>

              </defs>

              <g>
                  <rect x="-50" y="-50" width="200" height="200" fill="url('#radialGradient3')" 
                  filter={`url(#displacementFilter${props.slug})`}
                  />
                  {/* <text x="25%" y="75%" className={styles.idx}>{props.idx + 1}</text> */}
                  {/* <rect x="-50" y="-50" width="200" height="200" fill="#0060ff" filter="url(#nnnoise-filter)"/> */}
              </g>
            </svg>
            <svg className={styles.cardHologram}
              viewBox="0 0 100 100"
              preserveAspectRatio='none'
            >
              <defs>
                  <filter id={'displacementFilterHologramPortfolioCard'+props.idx} >
                    
                    <feTurbulence 
                      type="turbulence" 
                      baseFrequency="0.03" 
                      numOctaves="1" 
                      result="noise" 
                      seed={props.idx+1}
                      />
                    <feDisplacementMap in="SourceGraphic" in2="noise" scale="60" xChannelSelector="R" yChannelSelector="G" />

                  </filter>
                  <radialGradient id={'radialGradientHologramPlaygroundCard'+props.idx}  cx = "50%" cy = "50%" r = "50%" fx = "25%" fy = "25%">
                    <stop offset="10%" stopColor="rgba(255,255,255, 0)" />
                    <stop offset="20%" stopColor="rgba(255,255,255, 0)" />
                    {
                        [colors.blue, colors.blue, colors.blue, colors.green, colors.yellow, colors.orange, colors.orange].map((color, idx)=>{
                            return <stop key={'card3offset1'+idx} offset={`2${idx}%`} stopColor={color} />
                        })
                    }
                    <stop offset="28%" stopColor="rgba(255,255,255, 0)" />
                    <stop offset="50%" stopColor="rgba(255,255,255, 0)" />
                    {
                        [colors.blue, colors.blue, colors.blue, colors.green, colors.yellow, colors.orange, colors.orange].map((color, idx)=>{
                            return <stop key={'card3offset2'+idx} offset={`5${idx}%`} stopColor={color} />
                        })
                    }
                    <stop offset="58%" stopColor="rgba(255,255,255, 0)" />
                    <stop offset="70%" stopColor="rgba(255,255,255, 0)" />
                    {
                        [colors.blue, colors.blue, colors.blue, colors.green, colors.yellow, colors.orange, colors.orange].map((color, idx)=>{
                            return <stop key={'card3offset3'+idx} offset={`7${idx}%`} stopColor={color} />
                        })
                    }
                    <stop offset="78%" stopColor="rgba(255,255,255, 0)" />
                    <stop offset="100%" stopColor="rgba(255,255,255, 0)" />
                  </radialGradient>

              </defs>
              <g filter={`url(#displacementFilterHologramPortfolioCard${props.idx})`}>
                <rect id="holograms" x="-50" y="-50" width="200" height="200" fill={`url(#radialGradientHologramPlaygroundCard${props.idx}`}
                  transform={`translate(${mousePositionKeeped.x * 0.12} ${mousePositionKeeped.y*0.14})`}
                />
              </g>
            </svg>
          </div>
        </div>
        {/* <div className={styles.innerShape} >
          {props.slug === 'eat-salad' && 
            <img 
              src={appleImg}
              style={{transform: `translate(${mousePosition.x * 0.1}px, ${mousePosition.y*0.1}px)`}}
            />
          }
          {props.slug === 'luckysit' && 
            <img 
              src={torusImg}
              style={{transform: `translate(${mousePosition.x * 0.1}px, ${mousePosition.y*0.1}px)`}}
            />
          }
          {props.slug === 'solidarite-femmes' && 
            <img 
              src={heartImg}
              style={{transform: `translate(${mousePosition.x * 0.1}px, ${mousePosition.y*0.1}px)`}}
            />
          }
        </div> */}
        {/* <div className={styles.portolioProjectTitle}>
          <h4>
            {props.name}                    
          </h4>
        </div> */}
      </div>
    </div>
    </>
  )
}