import { useState } from 'react';
import { Routes, Route, useLocation} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';

import Page404 from './Components/404/404';
import Scene from './Components/Scene/Scene';
import Loader from './Components/Loader/Loader';
import Nav from './Components/Nav/Nav'
import Footer from './Components/Footer/Footer'
import HomePage from './Pages/HomePage/HomePage'
import About from './Pages/About/About'
import PlaygroundGrid from './Pages/PlaygroundGrid/PlaygroundGrid'
import Portfolio from './Pages/Portfolio/Portfolio';
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import './App.scss';



export default function App() {

  const location = useLocation()
  const [canvasLoaded, setCanvasLoaded] = useState(false)

  const setLoading = (isLoaded) => {
    setCanvasLoaded(isLoaded)
  }

  return (

    <div className="App">
      <Scene fullyLoaded={(isLoaded)=>setLoading(isLoaded)}/>
      <AnimatePresence mode="wait" initial={false}>
      {!canvasLoaded && <Loader key="loader"/>}

        <Routes location={location} key={location.pathname}>
          <Route path='*' element={<Page404/>}/>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="playground" element={<PlaygroundGrid />}/>
          <Route path="portfolio" element={<Portfolio />}/>
        </Routes>
      </AnimatePresence>
      <Nav/>
      <Footer/>
      <div className="contacts-links">
        <a className="mail-link" href="mailto:selmaozatalay@gmail.com">*selmaozatalay@gmail.com*</a>
        <a href="www.linkedin.com/in/selma-ozatalay-19534883" target='_blank'>
          <FaLinkedinIn />
        </a>
        <a href="https://github.com/SelmaOzatalay" target='_blank'>
          <FaGithub/>
        </a>
      </div>

      {/* <Loader/> */}
    </div>

  );
}