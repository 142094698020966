import {useState, useEffect, useRef} from 'react'
import {motion} from 'framer-motion';

import PortfolioCard from '../../Components/PortfolioCard/PortfolioCard'
import PortfolioDetail from '../PortfolioDetail/PortfolioDetail'
import portfolioData from '../../Data/portfolioData.json'

import styles from './portfolio.module.scss'


export default function Portfolio() {

  const containerRef = useRef(null)
  const scrollContainerRef = useRef(null)

  const [portfolioProjects, setPortfolioProjects] = useState([])

  const [rectAndSlug, setRectAndSlug] = useState(null)
  const [randomPosWrapper, setRandomPosWrapper] = useState([])
  const [scrollTop, setScrollTop] = useState(0)

  const [detailOpen, setDetailOpen] = useState(false)

  const [currentProjectIdx, setCurrentProjectIdx] = useState(1)


  useEffect(() => {

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    let portfolioProjectsTemp = []
    let randomPosTemp = []

    portfolioData.portfolioData.forEach((data, idx)=>{
      portfolioProjectsTemp.push({
        ...data,
        rotateFactor: getRandomArbitrary(1,6),
        // animationIsComplete: false
      })
      randomPosTemp.push(`translate(
        ${isEven(idx) ? getRandomArbitrary(1,9)*10 : -getRandomArbitrary(1,9)*10}px, 
        ${isEven(idx) ? getRandomArbitrary(1,9)*10 : -getRandomArbitrary(1,9)*10}px) 
      rotate(${isEven(idx) ? getRandomArbitrary(1,4)*4 : -getRandomArbitrary(1,4)*4}deg)`)
    })

    setPortfolioProjects(portfolioProjectsTemp)
    setRandomPosWrapper(randomPosTemp)

    if (containerRef.current) {
      containerRef.current.addEventListener('wheel',(e)=>{

        e.preventDefault();

        let deltaY = e.deltaY;
        let speed = deltaY / 6;
        
        containerRef.current.scrollTop += speed;
        setScrollTop(scrollContainerRef.current.getBoundingClientRect().y)
        
      })
  
      // return () => {
      //   containerRef.current.removeEventListener("wheel", setScrollTop(0))
      // }
    }

  },[])

  useEffect(()=>{
    portfolioData.portfolioData.forEach((project,idx)=>{
      if (-(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) >= 0 &&
      -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < window.innerHeight ) {
        // console.log(project)
        setCurrentProjectIdx(idx+1)
      }
    })
  },[scrollTop])

  function isEven (number) {
    return number % 2 === 0;
  }

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  function scrollFactorValue(idx, randomRange, multiplyFactor) {
    return (percentage(window.innerHeight * idx + scrollTop, window.innerHeight * idx) * multiplyFactor) / randomRange
  }

  return (
    <>
      <div ref={containerRef} className={styles.portfolioScrollContainer}>
      <motion.div

          // animate={{ 
          //   opacity: 1,
          //   transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}}
          // }}
          exit={{ 
            opacity: 0,
            transition: { opacity: {duration: 1, ease: 'circOut'}}
          }}          
          className={styles.portfolioSection} 
          style={{
            // opacity: detailOpen ? 0 : 1,
            height: window.innerHeight * portfolioData.portfolioData.length
          }}
          >
          <div className={styles.portfolioProjectsTitles} ref={scrollContainerRef}>
            {portfolioProjects.map((project, idx)=>{
              return (
                <div className={styles.portolioProjectTitle} key={'ptfl-title'+idx}>
                  <h3>
                    {Array.from(project.name).map((letter,idx)=>{
                      return <span key={'titleLetterPtfl'+idx} className="title-letter" style={{animationDelay: (getRandomArbitrary(1,9)*0.1)+'s'}}>{letter}</span>
                    })}                    
                  </h3>
                </div>
              )
            })}
          </div>
          <motion.div
          initial={{ 
            y: 200, 
            opacity: 0 
          }}
          animate={{ 
            y: 0, 
            opacity: 1,
            transition: { opacity: {duration: 1.6, ease: 'circOut', delay: 0}, y: {duration: 0.6,delay: 0, ease: 'circOut'}}
          }}
          // exit={{ 
          //   // x: -200, 
          //   // y: -200, 
          //   opacity: 0,
          //   transition: { opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
          // }}          
          className={styles.portfolioSection} 
          style={{
            opacity: detailOpen ? 0 : 1,
            height: window.innerHeight * portfolioData.portfolioData.length
          }}
          >

          {portfolioProjects.map((project, idx)=>{
            return (
              <div 
                className={styles.portfolioCardWrapper}
                style={{
                  transform: randomPosWrapper[idx],
                  pointerEvents: 
                  -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) >= 0 
                  &&
                  -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < window.innerHeight 
                  ? 
                  'initial': 'none'
                }}
                key={'PortfolioCard'+idx} 
              >
                <div 
                  style={{ 
                    transform: `
                      translateY(${-(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < 0 ? -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) : 0}px)
                      translateX(${-(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < 0 ? -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) : 0}px)
                      rotate(${ -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < 0 ? 
                        isEven(idx) ? -scrollFactorValue(idx,project.rotateFactor,0.01) : scrollFactorValue(idx,project.rotateFactor,0.01) 
                      : 0}turn)
                    `,
                    filter: `blur(${
                      -(window.innerHeight*(idx+1)) - (scrollTop - window.innerHeight) < 0 ? 
                      percentage((window.innerHeight*(idx+1)) + (scrollTop - window.innerHeight), window.innerHeight * (idx + 1) ) * 0.5
                    :0}px)` 
                  }}
                >
                  <PortfolioCard
                    name={project.name} 
                    idx={idx} 
                    projectsLength={portfolioData.portfolioData.length} 
                    slug={project.slug}
                    tags={project.tags}
                    isEven={false}
                    transitionComplete={currentProjectIdx === (idx+1)}
                    cardOpenEvent={
                      (rect, slug)=>{
                          setRectAndSlug({rect, slug: slug})
                          setDetailOpen(true)
                        }
                      }
                  />
                </div>
              </div>
            )
          })}


          <div className={styles.pagination}>
            {currentProjectIdx}/{portfolioData.portfolioData.length}
          </div>
          </motion.div>
          </motion.div>
        {/* </div> */}
        {detailOpen && rectAndSlug && rectAndSlug.slug &&
          // put playground detail here beacause zIndex problem with the menu
          <PortfolioDetail 
            slug={rectAndSlug.slug}
            rect={rectAndSlug.rect}
            closeDetail={()=>setDetailOpen(false)}
          />
        }
      </div>
    </>
  )
}