import { useEffect, useState, useRef, Image } from 'react'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MdLaunch } from "react-icons/md";

import styles from './portfolioDetail.module.scss'
import data from '../../Data/portfolioData.json'



export default function PortfolioDetail(props) {
    const [currentProject, setCurrentProject] = useState(null)

    const detail = useRef(null)

    
    useEffect(()=>{
        if (props.slug) {
            data.portfolioData.forEach((d)=>{
                if (d.slug === props.slug) {
                    console.log(d)
                    setCurrentProject(d)
                }
            })
        }
    },[])

    // useEffect(()=>{
    //     console.log('project :::',currentProject)

    //     if (currentProject.name) {
    //         console.log('imgs :::',currentProject.name)
    //     }
    // },[currentProject])

    const close = () => {
        props.closeDetail(false)
    }

    return (
        <>
        {   
        currentProject &&     
            <div className={styles.portfolioDetail}>
                <motion.div
                    ref={detail}
                    initial={props.rect}
                    animate={{
                        width: [props.rect.width, props.rect.width, props.rect.width, window.innerWidth - 40],
                        height: [props.rect.height, props.rect.height, props.rect.height, window.innerHeight - 106],
                        top: [props.rect.top, props.rect.top, props.rect.top, 86],
                        left: [props.rect.left, props.rect.left, props.rect.left, 20],
                        borderRadius: [8, 8, 8, 20],
                        opacity: [0, 0, 1, 1]
                    }}
                    transition={{
                        duration: 1.4, 
                        ease: 'linear',
                        times: [0, 0.1, 0.6, 1]
                    }}
                    className={styles.backgroundDetail}
                >
                    <svg 
                        viewBox='0 0 1723 886' 
                        preserveAspectRatio="none"
                    >
                        <path 
                            d="M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z"
                        >
                            <animate 
                            repeatCount="1" 
                            begin="0.6s"
                            attributeName="d" 
                            dur="1.6s"
                            attributeType="XML"
                            values="
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                                M1723,842c0,43-246.11-64-318-64-221.53,0-340.74,99-562.27,99-179.5,0-302.23-132-481.73-132C259.59,745,0,893,0,838V23c0-51,186,48,320,46C527.27,65.91,688.06,0,895.35,0c226.73,0,269.92,78,496.65,78,49.16,0,331-91,331-59V842Z;
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                            "
                            keyTimes="0;0.2;0.3;1"
                            >
                            </animate>
                        </path>
                    </svg>
                </motion.div>
                <motion.div className={styles.portfolioContentWrapper}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    transition={{
                        duration: 1, 
                        ease: 'easeOut',
                        delay: 2
                    }}
                >
                    <div className={styles.closeButton} onClick={()=>close()}>
                        <svg viewBox="0 0 34 34">
                            <g>
                                <line x1="2" y1="2" x2="32" y2="32" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '4px'}}/>
                                <line x1="32" y1="2" x2="2" y2="32" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '4px'}}/>
                            </g>
                        </svg>
                    </div>
                    <motion.div
                        initial={{
                            y: '50%'
                        }}
                        animate={{
                            y: 0
                        }}
                        transition={{
                            duration: 0.6, 
                            ease: 'easeOut',
                            delay: 2
                        }}
                    >
                        <div className={styles.titleWrapper}>
                            <h2>{currentProject.name}</h2>
                            <Link to={currentProject.url} target='blank' className="button">Visit website <MdLaunch/></Link>
                        </div>
                    
                        <div className="tagWrapper">
                            <div className="tag">{currentProject.type}</div>
                            {currentProject.tags.map((tag, idx)=>{
                                return <div className="tag" key={'tag'+props.slug+'detail'+idx}>{tag}</div>
                            })}
                        </div>
                        <p>
                            {currentProject.infos}
                        </p>
                    </motion.div>
                    {currentProject.type === 'Application' ?
                    <div className={styles.imgWrapperMobile}>
                        {currentProject.imgs.map((img, idx)=>{
                            return <LazyLoadImage
                            key={'ptfl-img'+idx}
                            // effect="opacity"
                            // wrapperProps={{
                            //     style: {transitionDelay: "1s"},
                            // }}
                            src={img} />
                        })}
                    </div>
                    :
                    <div className={styles.imgWrapper}>
                        {currentProject.imgs.map((img, idx)=>{
                            return <LazyLoadImage
                            key={'ptfl-img'+idx}
                            // effect="opacity"
                            // wrapperProps={{
                            //     // If you need to, you can tweak the effect transition using the wrapper style.
                            //     style: {transitionDelay: "1s"},
                            // }}
                            src={img} />
                        })}
                    </div>
                    }
                </motion.div>
            </div>
            
        }

        </>
    )
}