import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { useProgress } from '@react-three/drei'
import { AnimatePresence, motion } from 'framer-motion';
import Hero from './Hero3d';
import AnimatedFloor from './FloorMaterial';
import styles from './scene.module.scss'
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';

export default function Scene(props) {
  
  const groupRef = useRef(null)
  const location = useLocation();

  const [pos, setPos] = useState({up: false, scale: 0 });
  const [currentPageHome, setCurrentPageHome] = useState(true)

  const [cprogress, setCProgress] = useState(false)

  useEffect(()=>{
    if (location.pathname === '/') {
      setCurrentPageHome(true)
    } else {
      setCurrentPageHome(false)
      setPos({
        up: false,
        scale: 0
      });
    }
  },[])

  useEffect(()=>{
    // console.log(location)
    if (location.pathname === '/') {
      setCurrentPageHome(true)
    } else {
      setCurrentPageHome(false)
      setPos({
        up: false,
        scale: 0
      });
    }
  },[location])

  useProgress((state) => {
    // console.log(state)
    if (state.progress === 100) {
      const timeOut = setTimeout(()=>{
        setCProgress(true)
        props.fullyLoaded(true)
      },4000)
      return ()=>clearTimeout(timeOut)
    }
  })

  // useEffect(()=>{
  //   console.log(cameraPos)
  // },[cameraPos])

  const onScroll = (e) => {
    const direction = e.deltaY > 0 ? true : false

    setPos({
      up: direction,
      scale: e.deltaY
    });
  };

  const MoveCamera = () => {

    useFrame((state)=>{

      // if (cameraPos < 100) {
      //   setCameraPos(cameraPos+1)
      // }

      if (groupRef.current) {
          groupRef.current.rotation.x = state.pointer.y*0.2
          groupRef.current.rotation.y = state.pointer.x*0.2
      }

    })

  }



  return (
    <div className={styles.scene}>
      <Canvas
        linear={true}
        onWheel={(e)=>onScroll(e)}
        camera={{ 
          position: [100, 80, 100], 
          fov: 55, 
          near: 1, 
          far: 20000, 
          zoom: 1.5, 
          rotation: [-0.6747409422235529, 0.6629662362946376, 0.35] 
        }}
        gl={{
          antialias: true,
          alpha: true,
          powerPreference: 'high-performance'
        }}
      >
        <group rotation={[0,0,0]} ref={groupRef}>
          <AnimatedFloor/>
          {/* <AnimatePresence> */}
            <Hero fullyLoaded={cprogress}/>
          {/* </AnimatePresence> */}
        </group>
        {/* <GetCameraPos/> */}
        <MoveCamera/>
      </Canvas>
      { currentPageHome && 
        <ScrollIndicator wheelDetails={pos}/>
      }
    </div>
  );
}

