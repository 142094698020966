import { motion } from 'framer-motion'
import styles from './404.module.scss'


export default function Page404() {

    return (
        <motion.div 
            className={styles.page404}
            exit={{opacity: 0,transition:{duration: 0.5}}} 
        >
            <div className={styles.textWrapper}>
                <div className={styles.loading}>404</div>
            </div>
        </motion.div>
    )
}