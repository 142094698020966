import {useState, useEffect} from 'react'
import {motion} from 'framer-motion';
import PlaygroundCard from '../../Components/PlaygroundCard/PlaygroundCard'
import PlaygroundDetail from '../PlaygroundDetail/PlaygroundDetail'
import Dropdown from '../../Components/Dropdown/Dropdown'
import playgroundData from '../../Data/playgroundData.json'
import styles from './playgroundGrid.module.scss'


export default function PlaygroundGrid() {

  const variants = {
    open: { opacity: 1},
    closed: { opacity: 0},
  }

  // const [dataTransition, setDataTransition] = useState(false);
  // const [transitionAnimationState, setTransitionAnimationState] = useState(false)

  const [filteredProjects, setFilteredProjects] = useState(playgroundData)

  const [detailOpen, setDetailOpen] = useState(false)
  const [rectAndSlug, setRectAndSlug] = useState(null)


  const projectsType = [
    {
      name: 'All',
      id: 'all'
    },
    {
      name: 'Experiments',
      id: 'experiments'
    },
    {
      name: 'Tool',
      id: 'tool'
    },
    {
      name: 'Web design',
      id: 'web-design'
    }
  ]

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  },[])


  // useEffect(()=>{
  //   const tm = setTimeout(()=>{
  //     setDataTransition(false)
  //   },500)
  //   return ()=>clearTimeout(tm)
  // },[filteredProjects])

  const isEven = (number) => {
    return number % 2 === 0;
  }

  const filterProjectsByType = (filter) => {
    // setDataTransition(true)
    const tm = setTimeout(()=>{      
      let result;
      if (filter == 'all') {
        result = playgroundData.playgroundData
      } else {
        result = playgroundData.playgroundData.filter((project) => project.type === filter)
      }
      setFilteredProjects({playgroundData: result});
    },500)
    return ()=>clearTimeout(tm)
  } 

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  return (
    <>
      <motion.div
        initial={{ 
          opacity: 0 
        }}
        animate={{ 
          opacity: 1, 
          transition: { opacity: {duration: 0.6, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
        }}
        exit={{ 
          opacity: 0,
          transition: {delay: 0, opacity: {duration: 0.6, ease: 'circOut'}, x: {duration: 0.6}, y: {duration: 0.6}}
        }}        
        className={styles.playgroundSection} 
      >
        <div className={styles.opacityContainer}
          style={{
            opacity: detailOpen ? 0 : 1
          }}
        >
          <h2>{Array.from('Playground').map((letter, idx)=>{
            return <span key={'titleLetterPlayground'+idx} className='title-letter' style={{animationDelay: getRandomArbitrary(1,9)*0.1+'s'}}>{letter}</span>
          })}</h2>
          <div className={styles.playgroundNavigation}>
              <Dropdown options={projectsType} sendOption={filterProjectsByType}/>
          </div>

          <div className={styles.cardGrid}>
            {filteredProjects.playgroundData.map((project, idx)=>{
              return (

                <div key={'playgroundCard'+idx} className="enter-animation" style={{animationDelay: idx*0.2+'s'}}>
                  <PlaygroundCard 
                    name={project.name} 
                    idx={idx} 
                    projectsLength={filteredProjects.playgroundData.length} 
                    isEven={isEven(idx)}
                    slug={project.slug}
                    tags={project.tags}
                    cardOpenEvent={
                      (rect, slug)=>{
                          setRectAndSlug({rect, slug: slug})
                          setDetailOpen(true)
                        }
                      }
                  />
                </div>

              )
            })}
            </div>
          </div>
      </motion.div>
      {detailOpen && rectAndSlug && rectAndSlug.slug &&
        // put playground detail here beacause zIndex problem with the menu
        <PlaygroundDetail 
          slug={rectAndSlug.slug}
          rect={rectAndSlug.rect}
          closeDetail={()=>setDetailOpen(false)}
        />
      }
      </>
  )
}