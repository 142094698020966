import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './playgroundCard.module.scss'
import colors from '../../styles/colors.module.scss'
import { LuExpand } from "react-icons/lu";
// import { MdOpenInNew } from "react-icons/md";

export default function PlayrgoundCard(props) {
  const [rect, setRect] = useState({width:0, height:0, top: 0, left: 0})
  const [mousePosition, setMousePosition] = useState({x:0, y:0})
  const [mousePositionKeeped, setMousePositionKeeped] = useState({x:0, y:0})
  const cardRef = useRef()

  const setCardRect = () => {
    if (cardRef.current) {
      var rect = cardRef.current.getBoundingClientRect();
      setRect({
        width: rect.width, 
        height: rect.height, 
        top: rect.top, 
        left: rect.left
      })
    }
  }

  const makePerspective = (e) => {
    if (rect.width !== 0 && rect.height !== 0 && rect.top !== 0 && rect.left !== 0) {
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top; 

      var centerX = rect.width / 2
      var centerY = rect.height / 2

      var finalX = x > centerX ? x - centerX : -(centerX - x)
      var finalY = y > centerY ? y - centerY : -(centerY - y)

      setMousePosition({x:finalX, y:finalY});
      setMousePositionKeeped({x:finalX, y:finalY});
    }
  }

  const resetPerspective = () => {
    setMousePosition({x:0, y:0})
  }

  const goToDetail = () => {
    props.cardOpenEvent(rect, props.slug)
  }


  return (
    <>
    <div className={styles.projectCardWrapper}
    onMouseMove={(e)=>makePerspective(e)} 
    onMouseLeave={resetPerspective} 
    onMouseEnter={setCardRect}
    ref={cardRef}
    >
      <div
        className={styles.projectCard} 
        style={{
          transform: `perspective(600px) rotateY(${mousePosition.x / 5}deg) rotatex(${mousePosition.y / 5}deg) ${mousePosition.x !== 0 && mousePosition.y !== 0 ? 'scale(1.1)':'scale(1)'}`,
          transition: mousePosition.x !== 0 && mousePosition.y !== 0 ? 'none' : 'transform 0.6s ease-in',
        }}
        onClick={()=>goToDetail()}
      >
        <svg className={styles.cardReflection}
          viewBox="0 0 100 100"
          preserveAspectRatio='none'
        >
          <defs>

            <linearGradient id="lightgradient" x1="0" x2="1" y1="0" y2="1">
              <stop offset="0.3" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset="0.35" stopColor="rgba(160,209,251, 0.1)"></stop>
              <stop offset="0.40" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset={0.5} stopColor="rgba(160,209,251, 0.3)">
              </stop>
              <stop offset="0.6" stopColor="rgba(160,209,251, 0)"></stop>
              <stop offset="0.8" stopColor="rgba(160,209,251, 0.1)"></stop>
            </linearGradient>

          </defs>
          
          <rect x="-50" y="-50" width="200" height="200" fill={'url(#lightgradient)'}
            transform={`translate(${mousePositionKeeped.x * 0.1} ${mousePositionKeeped.y*0.1})`}
          ></rect>
        </svg>


        <h3>{props.name}</h3>

        <div className={styles.tagContainer}>
          <div className="tagWrapper">
            {props.tags.map((tag)=>{
              return <div className="tag" key={tag+props.slug}>{tag}</div>
            })}
          </div>
        </div>

        <div className={styles.imgContainer}>
          <svg className={styles.cardImg}
            viewBox="0 0 100 100"
            preserveAspectRatio='none' 
            >
            <defs>
              <filter id={'displacementFilterPlaygroundCard'+props.idx} >
                
                <feTurbulence 
                  type="turbulence" 
                  baseFrequency="0.03" 
                  numOctaves="1" 
                  result="waves" 
                  seed={props.idx+1}
                  />
                {/* <feImage href={displacementImg} preserveAspectRatio="none" result="waves" width="200" height="200" x="0" y="0">
                </feImage> */}
                <feDisplacementMap in="SourceGraphic" in2="waves" scale="80" xChannelSelector="G" yChannelSelector="B" />

              </filter>

              <linearGradient id={'radialGradientPlaygroundCard'+props.idx}  x1={`0`} x2={`100%`} y1={`0`} y2={`100%`}>
                <stop offset="10%" stopColor={colors.darkGrey} />
                <stop offset="38%" stopColor={colors.darkGrey} />
                {
                    [colors.blue, colors.blue, colors.middleBlue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                        return <stop key={'card3offset2'+idx} offset={`4${idx/1.5}%`} stopColor={color} />
                    })
                }
                <stop offset="46%" stopColor={colors.darkGrey} />
                <stop offset="100%" stopColor={colors.darkGrey} />
              </linearGradient>
            </defs>

            <g style={{
              transformBox: 'fill-box',
              transformOrigin: 'center',
              transform: `rotate(${props.idx*10}deg)`,
            }}>
                <rect x="-50%" y="-50%" width="200%" height="200%" fill={`url('#radialGradientPlaygroundCard${props.idx}')`} 
                filter={`url('#displacementFilterPlaygroundCard${props.idx}')`}
                />

            </g>
          </svg>
          <svg className={styles.cardHologram}
            viewBox="0 0 100 100"
            preserveAspectRatio='none'
          >
          <defs>
              <filter id={'displacementFilterHologramPlaygroundCard'+props.idx} >
                
                <feTurbulence 
                  type="turbulence" 
                  baseFrequency="0.03" 
                  numOctaves="1" 
                  result="noise" 
                  seed={props.idx+1}
                  />
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="60" xChannelSelector="R" yChannelSelector="G" />

              </filter>
              <linearGradient id={'radialGradientHologramPlaygroundCard'+props.idx}  x1={`0`} x2={`100%`} y1={`0`} y2={`100%`}>
                <stop offset="10%" stopColor={colors.darkGrey} />
                <stop offset="38%" stopColor={colors.darkGrey} />
                {
                    [colors.blue, colors.blue, colors.middleBlue, colors.green, colors.yellow, colors.orange].map((color, idx)=>{
                        return <stop key={'card3offset2'+idx} offset={`4${idx/1.5}%`} stopColor={color} />
                    })
                }
                <stop offset="46%" stopColor={colors.darkGrey} />
                <stop offset="100%" stopColor={colors.darkGrey} />
              </linearGradient>

          </defs>
          <g style={{
              transformBox: 'fill-box',
              transformOrigin: 'center',
              transform: `rotate(${props.idx*10}deg)`,
            }} 
            filter={`url(#displacementFilterHologramPlaygroundCard${props.idx})`}
          >
            <rect id="holograms" x="-50" y="-50" width="200" height="200" fill={`url(#radialGradientHologramPlaygroundCard${props.idx}`}
              transform={`translate(${mousePositionKeeped.x * 0.12} ${mousePositionKeeped.y*0.14})`}
            />
          </g>
          
          </svg>
          <LuExpand className={styles.expandIcon}/>
        </div>
      </div>
    </div>
    </>
  )
}