import { useEffect } from 'react';
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";
import styles from './about.module.scss'
import { GiTrophyCup } from "react-icons/gi";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [distance, -distance]);
}

export default function About() {

  const { scrollYProgress } = useScroll();
  const y = useParallax(scrollYProgress, 60);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  },[])

  return (  
      <div className={styles.about}>

          <div className={`${styles.aboutText}`}>
            <div className="container">
              <motion.div
                initial={{ 
                  opacity: 0,
                  y: 50
                }}
                animate={{ 
                  opacity: 1,
                  y: 0,
                  transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
                }}
                exit={{ 
                  opacity: 0,
                  y: 50,
                  transition: {delay: 0, opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
                }}  
                className={styles.textLeft}
              >
                <h2>*Hello World*&#10084;*</h2>
              </motion.div>
              <div></div>
              <motion.div
                initial={{ 
                  opacity: 0,
                  y: 100
                }}
                animate={{ 
                  opacity: 1,
                  y: 0,
                  transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
                }}
                exit={{ 
                  opacity: 0,
                  y: 100,
                  transition: {delay: 0, opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
                }}
                className={styles.textFull}>
               *&#10084;* AVAILABLE FOR WORK * LOCATED IN PARIS, FRANCE *&#10084;*
              </motion.div>
              <div></div>
              <motion.div
                initial={{ 
                  opacity: 0,
                  y: 150
                }}
                animate={{ 
                  opacity: 1,
                  y: 0,
                  transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
                }}
                exit={{ 
                  opacity: 0,
                  y: 150,
                  transition: {delay: 0, opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
                }} className={styles.textRight}>
                <h3>
                  Welcome<br className="mobile-only"/> to my playground
                  {/* <br/>*** */}
                </h3>
              </motion.div>
              <motion.div
                initial={{ 
                  opacity: 0,
                  y: 200
                }}
                animate={{ 
                  opacity: 1,
                  y: 0,
                  transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
                }}
                exit={{ 
                  opacity: 0,
                  y: 200,
                  transition: {delay: 0, opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
                }} className={styles.textLeft}>
                <p>where creativity meets code to craft unique digital experiences :)</p>
              </motion.div>
              <div></div>
              <div></div>
              <div className={styles.textRight}>
                <div className={styles.awards}>
                  <h3>
                    <GiTrophyCup />
                  </h3>
                  <div className={styles.line}><span>AWWWARDS</span> Honorable mention (1)</div>
                  <div className={styles.line}><span>CSS DESIGN</span> Site of the day (1)</div>
                </div>
              </div>
            </div>
            <div className={styles.email}>
              <a href='mailto:selmaozatalay@gmail.com'>
                selmaozatalay@gmail.com
              </a>
            </div>
          </div>


        <motion.div 
          className={styles.heartShape} 
          style={{ y }}
          initial={{ 
            opacity: 0
          }}
          animate={{ 
            opacity: 0.8,
            transition: { opacity: {duration: 1, ease: 'circOut', delay: 0}, x: {duration: 0.6,delay: 0,}, y: {duration: 0.6,delay: 0}}
          }}
          exit={{ 
            opacity: 0,
            transition: {delay: 0, opacity: {duration: 1, ease: 'linear'}, x: {duration: 0.6}, y: {duration: 0.6}}
          }}  
        >
        </motion.div>
      </div>
  )
}