import colors from '../../styles/colors.module.scss'

export default function Heart() {
    return (
        <div className='svgHeartContainer'>
        <svg
            className="heartSvg"
            viewBox="0 0 64.77 60"
            width="65"
            height="60"
            // preserveAspectRatio="xMidYMid meet"
            x="0"
            y="0"
            >
            <defs>
            
              <filter id="displacementFilterHeart">
                <feTurbulence 
                  // type="turbulence" 
                  baseFrequency="0.01" 
                  numOctaves="1" 
                  // result="noise" 
                  seed="1"
                  />
                  
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="50" xChannelSelector="R" yChannelSelector="G" />
                {/* <feMorphology operator="dilate" radius="6"></feMorphology> */}

              </filter>

              <linearGradient id="linearGradientHeart" x1="0%" x2="100%" y1="0%" y2="100%">
                <stop offset="10%" stopColor={colors.darkGrey}>

                </stop>
                {/* <stop offset="15%" stopColor={colors.darkGrey} /> */}
                <stop offset="20%" stopColor={colors.darkGrey} />
                <stop offset="25%" stopColor={colors.blue} />
                {/* <stop offset="30%" stopColor={colors.blue} /> */}
                <stop offset="35%" stopColor={colors.middleBlue} />
                <stop offset="40%" stopColor={colors.green} />
                <stop offset="45%" stopColor={colors.green} />
                <stop offset="50%" stopColor={colors.yellow} />
                <stop offset="55%" stopColor={colors.orange} />
                <stop offset="60%" stopColor={colors.darkGrey} />
                {/* <stop offset="65%" stopColor={colors.darkGrey} /> */}
                <stop offset="70%" stopColor={colors.darkGrey} />
                <stop offset="75%" stopColor={colors.blue} />
                <stop offset="80%" stopColor={colors.blue} />
                <stop offset="85%" stopColor={colors.green} />
                <stop offset="90%" stopColor={colors.yellow} />
                <stop offset="95%" stopColor={colors.orange} />
                <stop offset="100%" stopColor={colors.darkGrey} />
                <animate attributeName="x1" dur="12000ms" values="10%; 200%; 10%" repeatCount="indefinite" />

              </linearGradient>





                <clipPath id="clipHeart">
                    {/* <polygon points="40 18 22 18 22 0 18 0 18 18 0 18 0 22 18 22 18 40 22 40 22 22 40 22 40 18"/> */}
                    <path d="M55.49,30.76c-10.58,6.99-18.12,17.17-23.11,29.24-5.12-12.36-12.95-22.75-23.9-29.75l-1.72-.9C2.67,26.42,0,21.62,0,16.19,0,7.25,7.25,0,16.19,0s16.19,7.25,16.19,16.19l-.16,.52,.16-.52C32.38,7.25,39.63,0,48.58,0s16.19,7.25,16.19,16.19c0,5.8-3.05,10.89-7.64,13.75l-1.64,.82Z"/>
                </clipPath>
            </defs>


            <rect x="-100%" y="-100%" width="200%" height="200%" fill="url('#linearGradientHeart')" 
            filter='url(#displacementFilterHeart)'
            clipPath='url(#clipHeart)'
            />
            
          </svg>
        </div>
    )
}