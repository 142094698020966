import { useRef } from 'react';
import * as THREE from 'three';
import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";
import { useThree, useLoader, useFrame, extend } from '@react-three/fiber';
import matcapTexture from '../../img/geoball3.jpg'

export const FloorMaterial = shaderMaterial(
  { 
    uTexture:new THREE.Texture(),
    uTime: 0 ,
    uProg: 5,
    transparent: true
  },
  // vertex shader
  glsl`
  precision mediump float;
  varying vec2 vUv;
  varying float wave;
  uniform float uTime;
  uniform float uProg;

  #pragma glslify: noise = require('../../shaders/3d.glsl')
  
  
  void main() {
    vec3 pos = position;
  
   
    pos.z += noise(vec3(pos.x * 4. + uTime*0.05, pos.y * 4., 0.)) * uProg;
    wave = pos.z;
    pos.z *= 3.;
    
  
    vUv = uv;
  
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
  }
  `,
  // fragment shader
  glsl`
  precision mediump float;

  varying vec2 vUv;
  varying float wave;
  
  uniform sampler2D uTexture;
  uniform float uTime;
  uniform float uProg;
    
  void main() {
    vec2 uv = vUv;
    vec2 dUv = vec2(uv.x, uv.y);
    vec3 ctexture;
    

      float w = wave;
      float r = texture2D(uTexture, dUv + vec2(0., 0.) + uProg * w * -0.02).r;
      float g = texture2D(uTexture, dUv + vec2(0., 0.) + uProg * w * -0.02).g;
      float b = texture2D(uTexture, dUv + vec2(0., 0.) + uProg * w * -0.02).b;
      ctexture = vec3(r, g, b);    

    
    gl_FragColor = vec4(ctexture, 1.);
  }
  `
)

extend({FloorMaterial})

export default function AnimatedFloor() {

    const [textureMap] = useLoader(THREE.TextureLoader, [matcapTexture]);
  
    const floorRef = useRef();
    const textureRef = useRef();
    const viewport = useThree(state => state.viewport)
  
    useFrame(({ clock }) => (
      textureRef.current.uTime = clock.getElapsedTime()
    ));

    
    return (
      <mesh 
        scale={[viewport.width * 3, viewport.height * 4, 1]} 
        position={[-100, -10, -viewport.height]}
        rotation={[-Math.PI / 2, 0, 0]} 
        ref={floorRef}
      >
        <planeGeometry args={[1, 1, 32, 32]} />
        <floorMaterial 
          ref={textureRef} 
          uTexture={textureMap}
          transparent={true}
          opacity={0.5}
        />
      </mesh>
    )
  }
