import { useState } from 'react'
import styles from './dropdown.module.scss'
import filterIcon from '../../img/icons/filter.svg'

export default function Dropdown(props) {
    const [selectedOption, setSelectedOption] = useState('All')
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const setOption = (option) => {
        let result = props.options.filter((opt) => opt.id === option)
        setSelectedOption(result[0].name)
        props.sendOption(option)
        setDropdownOpen(false)
    }

    return (
        <div className={` ${styles.dropdown}  ${dropdownOpen ? styles.dropdownOpen : undefined}`}>
            <div className={styles.selectedOptionWrapper}>
                <div className={styles.transformContainer}>
                    <div className={styles.selectedOptionBackground}></div>
                </div>
                <div className={styles.selectedOption} onClick={()=>setDropdownOpen(!dropdownOpen)}>
                    <img src={filterIcon} className={styles.icon}/>
                    {selectedOption}
                </div>
            </div>
            <div className={styles.optionWrapper}>
                <div className={styles.spacer}></div>
                {props.options.map((option, idx)=>{
                    return <span key={option.id+idx} className={selectedOption === option.name ? styles.dropdownOptionSelected : undefined} onClick={()=>setOption(option.id)}>{option.name}</span>
                })}
                <div className={styles.spacer}></div>
            </div>
        </div>
    )

}