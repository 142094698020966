import colors from '../../styles/colors.module.scss'

export default function Line(props) {
    return (
        // <div className='svgLineContainer'>
        <svg
            className="lineSvg"
            // viewBox="0 0 100% 2"
            // width="100"
            height="1"
            // preserveAspectRatio="xMidYMid meet"
            x="0"
            y="0"
            >
            <defs>

              <linearGradient id={`${props.id}linearGradientLine`} x1="0%" x2="100%">
                <stop offset="0%" stopColor={colors.lightGrey}>
                  <animate begin={props.begin+'s'} attributeName="stop-color" dur="20s" keyTimes="0;0.1;0.5;0.9;1" values={colors.lightBlue+';'+colors.lightBlue+';'+colors.lightGrey+';'+colors.lightBlue+';'+colors.lightBlue} repeatCount="indefinite" />
                </stop>
                <stop offset="44%" stopColor={colors.lightGrey}>
                  <animate begin={props.begin+'s'} attributeName="stop-color" dur="20s" keyTimes="0;0.1;0.5;0.9;1" values={colors.lightBlue+';'+colors.lightBlue+';'+colors.lightGrey+';'+colors.lightBlue+';'+colors.lightBlue} repeatCount="indefinite" />
                </stop>
                <stop offset="46%" stopColor={colors.blue} />
                <stop offset="48%" stopColor={colors.middleBlue} />
                <stop offset="50%" stopColor={colors.green} />
                <stop offset="52%" stopColor={colors.yellow} />
                <stop offset="54%" stopColor={colors.orange} />
                <stop offset="56%" stopColor={colors.lightGrey}>
                  <animate begin={props.begin+'s'} attributeName="stop-color" dur="20s" keyTimes="0;0.4;0.5;0.9;1" values={colors.lightBlue+';'+colors.lightBlue+';'+colors.lightGrey+';'+colors.lightBlue+';'+colors.lightBlue} repeatCount="indefinite" />
                </stop>
                <stop offset="100%" stopColor={colors.lightGrey}>
                  <animate begin={props.begin+'s'} attributeName="stop-color" dur="20s" keyTimes="0;0.4;0.5;0.9;1" values={colors.lightBlue+';'+colors.lightBlue+';'+colors.lightGrey+';'+colors.lightBlue+';'+colors.lightBlue} repeatCount="indefinite" />
                </stop>

              </linearGradient>

            </defs>


            <rect x="-100%" y="0" width="300%" height="1" fill={`url(#${props.id}linearGradientLine)`}>
              <animate begin={props.begin+'s'} attributeName="x" dur="20s" keyTimes="0;0.4;0.5;0.6;1" values="-200%;-170%;-100%;-30%;0%;" repeatCount="indefinite" />
            </rect>
            
          </svg>
        // </div>
    )
}