import colors from '../../styles/colors.module.scss'

export default function Cross() {
    return (
        <div className='svgCrossContainer'>
        <svg
            className="crossSvg"
            viewBox="0 0 40 40"
            width="40"
            height="40"
            // preserveAspectRatio="xMidYMid meet"
            x="0"
            y="0"
            >
            <defs>
            
              <filter id="displacementFilterCross">
                <feTurbulence 
                  // type="turbulence" 
                  baseFrequency="0.01" 
                  numOctaves="1" 
                  // result="noise" 
                  seed="1"
                  />
                  
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="50" xChannelSelector="R" yChannelSelector="G" />
                {/* <feMorphology operator="dilate" radius="6"></feMorphology> */}

              </filter>

              <linearGradient id="linearGradientCross" x1="0%" x2="100%" y1="0%" y2="100%">
                <stop offset="10%" stopColor={colors.darkGrey}>

                </stop>
                {/* <stop offset="15%" stopColor={colors.darkGrey} /> */}
                <stop offset="20%" stopColor={colors.darkGrey} />
                <stop offset="25%" stopColor={colors.blue} />
                {/* <stop offset="30%" stopColor={colors.blue} /> */}
                <stop offset="35%" stopColor={colors.middleBlue} />
                <stop offset="40%" stopColor={colors.green} />
                <stop offset="45%" stopColor={colors.green} />
                <stop offset="50%" stopColor={colors.yellow} />
                <stop offset="55%" stopColor={colors.orange} />
                <stop offset="60%" stopColor={colors.darkGrey} />
                {/* <stop offset="65%" stopColor={colors.darkGrey} /> */}
                <stop offset="70%" stopColor={colors.darkGrey} />
                <stop offset="75%" stopColor={colors.blue} />
                <stop offset="80%" stopColor={colors.blue} />
                <stop offset="85%" stopColor={colors.green} />
                <stop offset="90%" stopColor={colors.yellow} />
                <stop offset="95%" stopColor={colors.orange} />
                <stop offset="100%" stopColor={colors.darkGrey} />
                <animate attributeName="x1" dur="12000ms" values="10%; 200%; 10%" repeatCount="indefinite" />

              </linearGradient>





                <clipPath id="clipCross">
                    <polygon points="40 18 22 18 22 0 18 0 18 18 0 18 0 22 18 22 18 40 22 40 22 22 40 22 40 18"/>
                </clipPath>
            </defs>


            <rect x="-100%" y="-100%" width="200%" height="200%" fill="url('#linearGradientCross')" 
            filter='url(#displacementFilterCross)'
            clipPath='url(#clipCross)'
            />
            
          </svg>
        </div>
    )
}