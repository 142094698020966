import { Link } from "react-router-dom";
import { GiCardQueenDiamonds } from "react-icons/gi";
import { IoDiamond } from "react-icons/io5";
import styles from './footer.module.scss'

export default function Footer(props) {

  return (
    <footer className={styles.footer}>
      {/* <IoDiamond/> */}
      <div className={styles.footerText}>
        <p>
          Selma Özatalay<br/>
          Playground / Portfolio 2024
        </p>
      </div>
    </footer>
  )
}