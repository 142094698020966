import { useEffect, useState, useRef, Suspense } from 'react'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { FaGithub } from "react-icons/fa6";
import { MdOpenInNew } from "react-icons/md";

import Loader from '../../Components/Loader/Loader';

import styles from './playgroundDetail.module.scss'
import data from '../../Data/playgroundData.json'

export default function PlaygroundDetail(props) {
    const [currentProject, setCurrentProject] = useState(null)

    const detail = useRef(null)

    
    useEffect(()=>{

        if (props.slug) {
            data.playgroundData.forEach((d)=>{
                if (d.slug === props.slug) {
                    console.log(d)
                    setCurrentProject(d)
                }
            })
        }
    },[])

    // useEffect(()=>{
    //     console.log('PROJECT',currentProject)
    // },[currentProject])

    const close = () => {
        props.closeDetail(false)
    }

    function getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }

    return (
        <>
        {   
        currentProject &&     
            <div className={styles.playgroundDetail}>
                <motion.div
                    ref={detail}
                    initial={props.rect}
                    animate={{
                        width: [props.rect.width, props.rect.width, props.rect.width, window.innerWidth - 40],
                        height: [props.rect.height, props.rect.height, props.rect.height, window.innerHeight - 106],
                        top: [props.rect.top, props.rect.top, props.rect.top, 86],
                        left: [props.rect.left, props.rect.left, props.rect.left, 20],
                        borderRadius: [8, 8, 8, 20],
                        opacity: [0, 0, 1, 1]
                    }}
                    transition={{
                        duration: 1.4, 
                        ease: 'linear',
                        times: [0, 0.1, 0.6, 1]
                    }}
                    className={styles.backgroundDetail}
                >
                    <svg 
                        viewBox='0 0 1723 886' 
                        preserveAspectRatio="none"
                    >
                        <path 
                            d="M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z"
                        >
                            <animate 
                            repeatCount="1" 
                            begin="0.6s"
                            attributeName="d" 
                            dur="1.6s"
                            attributeType="XML"
                            values="
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                                M1723,842c0,43-246.11-64-318-64-221.53,0-340.74,99-562.27,99-179.5,0-302.23-132-481.73-132C259.59,745,0,893,0,838V23c0-51,186,48,320,46C527.27,65.91,688.06,0,895.35,0c226.73,0,269.92,78,496.65,78,49.16,0,331-91,331-59V842Z;
                                M1723,850.14c0,43-143.79,34-215.68,34-221.53,0-443.06,1-664.59,1-179.5,0-358.99-1-538.49-1-101.41,0-304.24,17-304.24-38V31.14C0-19.86,189,7.14,273.48,7.14c207.29,0,414.58,1,621.87,1,226.73,0,453.46,1,680.18,1,49.16,0,147.47-14,147.47,18V850.14Z;
                            "
                            keyTimes="0;0.2;0.3;1"
                            >
                            </animate>
                        </path>
                    </svg>
                </motion.div>
                <motion.div className={styles.playgroundContentWrapper}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    transition={{
                        duration: 1, 
                        ease: 'easeOut',
                        delay: 2
                    }}
                >
                    <div className={styles.closeButton} onClick={()=>close()}>
                        <svg viewBox="0 0 34 34">
                            <g>
                                <line x1="2" y1="2" x2="32" y2="32" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '4px'}}/>
                                <line x1="32" y1="2" x2="2" y2="32" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '4px'}}/>
                            </g>
                        </svg>
                    </div>
                    <div className={styles.topWrapper}>
                        <h2>
                            {Array.from(currentProject.name).map((letter, idx)=>{
                                return <span key={'titleLetterPlayground'+idx} className='title-letter' style={{animationDelay: 2+ getRandomArbitrary(1,9)*0.1+'s'}}>{letter}</span>
                            })}
                        </h2>
                        <span style={{display: 'flex'}}>
                            <Link to={currentProject.github} target="_blank" className={styles.githubLink}>
                                <FaGithub/>
                            </Link>
                            <Link className="button" to={currentProject.url} target="_blank">open in new tab <MdOpenInNew/></Link>
                        </span>
                    </div>
                    <motion.div
                        initial={{
                            y: '50%'
                        }}
                        animate={{
                            y: 0
                        }}
                        transition={{
                            duration: 0.6, 
                            ease: 'easeOut',
                            delay: 2
                        }}
                    >
                            <div className="tagWrapper">
                                {currentProject.tags.map((tag)=>{
                                    return <div className="tag" key={tag+props.slug+'detail'}>{tag}</div>
                                })}
                            </div>

                        <p>
                            {currentProject.infos}
                        </p>
                    </motion.div>
                    <Suspense fallback={<Loader/>}>
                        <iframe className={styles.projectPreview} src={currentProject.url}>
                            <p>Your browser does not support iframes.</p>
                        </iframe>
                    </Suspense>
                </motion.div>
            </div>
            
        }

        </>
    )
}