// import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
// import ScrollIndicator from '../../Components/ScrollIndicator/ScrollIndicator';
import Cross from '../../Components/Svg/Cross'
import Heart from '../../Components/Svg/HeartIcon'
import styles from './homePage.module.scss'

export default function HomePage() {


  return (
    <motion.div 
      className={styles.homePage}
      initial={{opacity: 0}}
      animate={{opacity: 1,transition:{duration: 0.6}}}
      exit={{opacity: 0,transition:{duration: 0.6}}}   
    >
      <div className={styles.homePresentation}>
        <Heart/>
        <h2>Front-end developer located in Paris <br/> available for work</h2>
      </div>
      <div className={styles.homeText}>
        <Cross/>
        <h3>scroll or use top menu <br/>to navigate between <br/>portfolio and playground</h3>
      </div>
      {/* <ScrollIndicator/> */}
    </motion.div>
  )
}