import { Link } from "react-router-dom";
import TextTransition from "../TextTransition/TextTransition";
import Line from '../Svg/Line'

import styles from './nav.module.scss'

export default function Nav(props) {

  return (
    <nav className={styles.nav}>
      <Link to="/"> 
        <h1>
          <span className={styles.logo}>SÖ</span> 
          <span className={styles.job}>CREATIVE DEVELOPER</span>
        </h1>
      </Link>
      <div className={styles.navItem}>
        <Link to="portfolio" id="nav-item1">
          <TextTransition>Portfolio</TextTransition>
          <Line id="nav-line-1" begin={'0'}/>
        </Link>
      </div>
      <div className={styles.navItem}>
        <Link to="playground" id="nav-item2">
          <TextTransition>Playground</TextTransition>
          <Line id="nav-line-2" begin={'10'}/>
        </Link>
      </div>
      <Link to="/about"  className={styles.aboutButton}>
        <div className={styles.transformContainer}>
          <div className={styles.buttonBackgroundRainbow}></div>
        </div>
        <div className={styles.buttonBackground}></div>
        <button id="about-button">* &#10084; <TextTransition>???</TextTransition> &#10084; *</button>
      </Link>
    </nav>
  )
}