import { motion } from 'framer-motion'
import styles from './loader.module.scss'


export default function Loader() {

    return (
        <motion.div 
            className={styles.loader}
            exit={{opacity: 0,transition:{duration: 0.5}}} 
        >
            <div className={styles.textWrapper}>
                {/* <div className={styles.logo}>SÖ</div>
                <div className={styles.heart1}>&#10084;</div>
                <div className={styles.heart2}>&#10084;</div>
                <div className={styles.heart3}>*</div> */}
                <div className={styles.loading}>*&#10084;* <span>LOADING</span> *&#10084;*</div>
            </div>
        </motion.div>
    )
}